import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { getCookie } from './sessionCookie.js'

const Languages = ['en', 'fr', 'pt', 'es'];
const language = getCookie('language') ? getCookie('language') : 'en';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: true,
      wait: true,
    },
    fallbackLng: language, //'en',
    debug: false,
    whitelist: Languages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    nsSeperator: false,
    keySeperator: false,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  })


export default i18n;