import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes, HashRouter } from 'react-router-dom'
import { getCookie } from './helpers/sessionCookie.js'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Plant = React.lazy(() => import('./views/pages/register/Plant'))
const Contact = React.lazy(() => import('./views/pages/register/Contact'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Validation = React.lazy(() => import('./views/pages/register/Validation'))
const Login = React.lazy(() => import('./views/pages/login/Login'))
const RequestPasswordReset = React.lazy(() => import('./views/pages/login/RequestPasswordReset'))
const ResetPassword = React.lazy(() => import('./views/pages/login/ResetPassword'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const TermsAndConditions = React.lazy(() => import('./views/pages/register/TermsAndConditions'))

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      authenticated: getCookie('name') !== false && getCookie('name') !== '' && getCookie('app_version') !== false && getCookie('app_version') === process.env.REACT_APP_VERSION,
      plantCreated: getCookie('plant-created') !== false && getCookie('plant-created') !== 'false',
      plantValidated: getCookie('plant-validated') !== false && getCookie('plant-validated') !== 'false',
    }
  }
  render() {
    return this.state.authenticated ? (
      <HashRouter>
        <Suspense fallback={loading}>
         
          <Routes>
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="/register/plant" name="Create plant" element={<Plant />} />
            <Route path="/register/contact" name="Validating plant" element={<Contact />} />
            <Route path="/tc" name="Terms and conditions" element={<TermsAndConditions />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>
    ) : (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route path="/requestPasswordReset" name="Request password reset" element={<RequestPasswordReset />} />
            <Route path="/resetPassword" name="Reset password" element={<ResetPassword />} />
            <Route path="/register" name="Register" element={<Register />} />
            <Route path="/validation" name="Validation" element={<Validation />} />
            <Route path="*" name="Login" element={<Login />} />
            <Route path="/tc" name="Terms and conditions" element={<TermsAndConditions />} />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
